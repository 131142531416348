import { Grid } from '@material-ui/core';
import React from 'react';

import Layout from '../Layout';
import MetaContainer from '../MetaContainer';
import Narrow from '../Narrow';
import PageTitle from '../PageTitle';

export default ({ pageContext: { imprint, searchIndex } }) => (
  <Layout background={false} searchIndex={searchIndex}>
    <Grid container justify="center" spacing={24}>
      <Grid item xs={12}>
        <Narrow>
          <PageTitle>{imprint.title.rendered}</PageTitle>
          <MetaContainer
            dangerouslySetInnerHTML={{ __html: imprint.content.rendered }}
          />
        </Narrow>
      </Grid>
    </Grid>
  </Layout>
);
